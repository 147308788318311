$primary-color: #282c34;
$secondary-color: #e1dbdb;
$tertiary-color: #61fe90;

.message{
    width:60%;
    text-align: left;
    padding:15px;
    margin: 15px;
    border-radius: 20px;
    & .btn {
        margin-left:90%
    }
    &.user {
        background-color: $secondary-color;
        color: #282c34;
        margin-left:calc(40% - 15px);
        box-sizing: border-box;
        
    }
    &.assistant {
        background-color: $tertiary-color;
        color: #282c34;
        
    }
}
.messages {
    margin-bottom:50vh;
}
.newMessage {
    position: fixed;
    bottom: 0;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    padding:25px;
    & .cargando {
        display: block;
        width: 100%;
        font-size:large;
    }
    & textarea {
        width: 88%;
        border-radius: 20px;
        padding: 20px;
        border: 3px solid #a4a4a4;
        resize: none;
        font-size: 1.2rem;
    }
}



